import { Components, Theme } from "@mui/material";

const MuiTabs: Components<Theme>["MuiTabs"] = {
  defaultProps: {
    textColor: "secondary",
    indicatorColor: "secondary",
  },
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      boxShadow: `0 -2px 0 ${palette.grey[300]} inset`,
    }),
    indicator: { height: 3 },
  },
};

export default MuiTabs;

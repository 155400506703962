import { Components, Theme } from "@mui/material";

const MuiMenuItem: Components<Theme>["MuiMenuItem"] = {
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      "&:hover": {
        backgroundColor: palette.grey["200"],
      },
      "&.Mui-selected": {
        backgroundColor: palette.secondary.background,
      },
      "&.Mui-selected:hover": {
        backgroundColor: palette.secondary.backgroundHover,
      },
    }),
  },
};

export default MuiMenuItem;

import React, { useEffect, useState } from "react";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { ApolloProvider, NormalizedCacheObject } from "@apollo/client";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { frFR } from "@mui/x-date-pickers/locales";
import frLocale from "date-fns/locale/fr";
import cookie from "js-cookie";

import AppLayout from "components/AppLayout";
import { InfobarProvider } from "components/InfobarContext";
import { SnackbarProvider } from "components/SnackbarContext";
import useApollo from "lib/apollo/apolloClient";
import { HelpCategory, HelpPost } from "types";
import createEmotionCache from "utils/createEmotionCache";
import theme from "utils/theme";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface PageProps {
  initialApolloState?: NormalizedCacheObject;
  email?: string | null;
  confirmationToken?: string;
  helpPosts?: HelpPost[];
  helpCategories?: HelpCategory[];
  helpPost?: HelpPost;
}

export interface MyAppProps extends AppProps<PageProps> {
  emotionCache?: EmotionCache;
}

const MyApp = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: MyAppProps) => {
  const router = useRouter();
  const { pathname, asPath } = router;
  const [accessToken, setAccessToken] = useState("");
  const apolloClient = useApollo(pageProps.initialApolloState, accessToken);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { access_token } = cookie.get();

    setAccessToken(access_token);
  }, []);

  useEffect(() => {
    const routePath = dangerouslyGlobalRoutePath(pathname, asPath);
    if (routePath) router.replace(routePath);
  }, [pathname]);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Embarq</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <InfobarProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={frLocale}
              localeText={
                frFR.components.MuiLocalizationProvider.defaultProps.localeText
              }
            >
              <CssBaseline />
              <AppLayout>
                <SnackbarProvider>
                  <Component {...pageProps} />
                </SnackbarProvider>
              </AppLayout>
            </LocalizationProvider>
          </InfobarProvider>
        </ThemeProvider>
      </ApolloProvider>
    </CacheProvider>
  );
};

export default MyApp;

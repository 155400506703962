import fetch from "isomorphic-fetch";

import handleNetworkError from "apis/handleNetworkError";
import handleResponse from "apis/handleResponse";
import setTokens from "utils/setTokens";

interface LoginParams {
  grant_type: "password";
  email: string;
  password: string;
}

interface RefreshTokenParams {
  grant_type: "refresh_token";
  refresh_token: string;
}

const getAccessToken = (params: LoginParams | RefreshTokenParams) =>
  fetch(`${process.env.NEXT_PUBLIC_API_URL}/oauth/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .catch(handleNetworkError)
    .then(handleResponse)
    .then(setTokens);

export default getAccessToken;

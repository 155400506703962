import { Components, Theme } from "@mui/material";

const MuiFormHelperText: Components<Theme>["MuiFormHelperText"] = {
  styleOverrides: {
    root: ({
      theme: {
        palette: { info },
      },
    }) => ({
      color: info.dark,
    }),
  },
};

export default MuiFormHelperText;

import { createContext, ReactNode, useMemo, useState } from "react";
import { AlertColor } from "@mui/material";

import AutoHideSnackbar from "./AutoHideSnackbar";

interface ContextValuesType {
  open: boolean;
  severity: AlertColor;
  autoHideDuration: number | null;
  message: ReactNode;
  title?: string;
  onClose?: () => void;
  anchorOrigin: {
    vertical: "top" | "bottom";
    horizontal: "left" | "center" | "right";
  };
}

type SnackbarContextType = [
  ContextValuesType,
  (context: ContextValuesType) => void,
];

const defaultContext: ContextValuesType = {
  open: false,
  severity: "info",
  autoHideDuration: 6000,
  message: "",
  anchorOrigin: { vertical: "top", horizontal: "center" },
};

const SnackbarContext = createContext<SnackbarContextType>([
  defaultContext,
  () => {},
]);

const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const [context, setContext] =
    useState<SnackbarContextType[0]>(defaultContext);

  const handleClose = () => {
    context.onClose?.();
    setContext({ ...context, open: false });
  };

  const contextValue = useMemo<SnackbarContextType>(
    () => [context, setContext],
    [context, setContext]
  );

  return (
    <SnackbarContext.Provider value={contextValue}>
      <AutoHideSnackbar {...context} onClose={handleClose} />
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarContext, SnackbarProvider };

import { setContext } from "@apollo/client/link/context";
import cookies from "js-cookie";

// Only used client side to retrieve access token on every request
const clientSideAuthLink = setContext((_, { headers }) => {
  if (typeof window === "undefined") return {};

  const token = cookies.get("access_token");
  // return the headers to the context so HttpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export default clientSideAuthLink;

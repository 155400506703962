import { Components, Theme } from "@mui/material";

const MuiIconButton: Components<Theme>["MuiIconButton"] = {
  styleOverrides: {
    sizeMedium: {
      padding: "4px",
      "& .MuiSvgIcon-root": {
        fontSize: "1.25rem",
      },
    },
    sizeLarge: {
      padding: "6px",
      "& .MuiSvgIcon-root": {
        fontSize: "1.5rem",
      },
    },
  },
};

export default MuiIconButton;

import { useMemo } from "react";
import { ApolloClient, ApolloLink } from "@apollo/client";
import { InMemoryCache, NormalizedCacheObject } from "@apollo/client/cache";
import { relayStylePagination } from "@apollo/client/utilities";

import contentfulApiLink from "./links/contentfulApiLink";
import graphqlApiLink from "./links/graphQLApiLink";

let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const createApolloClient = (
  accessToken?: string
): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === "contentful", // Routes the query to the proper client
      contentfulApiLink,
      graphqlApiLink(accessToken)
    ),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            payables: relayStylePagination(),
            payments: relayStylePagination(["paymentType"]),
            invoicesPayments: relayStylePagination(),
            settlementsPayments: relayStylePagination(),
            crewMembers: relayStylePagination(),
            missions: relayStylePagination(),
            onboardingTodos: relayStylePagination([
              "search",
              "month",
              "businessDeveloper",
            ]),
            invoices: relayStylePagination([
              "search",
              "state",
              "crewMemberId",
              "clientId",
              "missionId",
              "monthGte",
              "monthLte",
              "issuedOnGte",
              "issuedOnLte",
              "dueDate",
            ]),
            activityReports: relayStylePagination(),
            expenseReports: relayStylePagination(),
            ongoingMissionsWithoutActivityReport: relayStylePagination([
              "month",
            ]),
            employeeCommitteeServices: relayStylePagination(),
          },
        },
        Sailor: {
          fields: {
            missions: relayStylePagination(["year"]),
            activityReports: relayStylePagination(),
            expenseReports: relayStylePagination(),
            payments: relayStylePagination(["year"]),
            notifications: relayStylePagination(),
            invoices: relayStylePagination(["year"]),
            stats: {
              merge: true,
            },
            notes: {
              merge: false,
            },
            documents: {
              merge: false,
            },
            vehicles: {
              merge: false,
            },
          },
        },
        AccountManager: {
          fields: {
            todos: relayStylePagination(),
            notifications: relayStylePagination(),
          },
        },
        BusinessDeveloper: {
          fields: {
            todos: relayStylePagination(),
            notifications: relayStylePagination(),
          },
        },
        Expense: { fields: { comments: { merge: false } } },
        ExpenseReport: {
          fields: {
            expenses: {
              merge: false,
            },
            expensesStatesCount: { merge: true },
          },
        },
        Document: { merge: false },
        Client: {
          fields: {
            contacts: { merge: false },
            clientUsers: { merge: false },
            clientAdmins: { merge: false },
          },
        },
      },
      possibleTypes: {
        CrewMemberInterface: ["AccountManager", "BusinessDeveloper"],
        ClientUserInterface: ["ClientUser", "ClientAdmin"],
        UserInterface: ["Sailor", "CrewMemberInterface", "ClientUserInterface"],
      },
    }),
  });

export const initializeApollo = (
  initialState?: NormalizedCacheObject,
  accessToken?: string
) => {
  const apolloClient = globalApolloClient ?? createApolloClient(accessToken);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) globalApolloClient = apolloClient;

  return apolloClient;
};

const useApollo = (
  initialState?: NormalizedCacheObject,
  accessToken?: string
) => {
  const store = useMemo(
    () => initializeApollo(initialState, accessToken),
    [initialState]
  );
  return store;
};

export default useApollo;

import { Components, Theme } from "@mui/material";

const MuiListItem: Components<Theme>["MuiListItem"] = {
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: palette.grey["200"],
      },
    }),
  },
};

export default MuiListItem;

import { Snackbar, SnackbarProps } from "@mui/material";

import StyledAlert, { StyledAlertProps } from "./basics/StyledAlert";

interface AutoHideSnackbarProps
  extends SnackbarProps,
    Pick<StyledAlertProps, "title" | "severity"> {
  anchorOrigin: {
    vertical: "top" | "bottom";
    horizontal: "left" | "center" | "right";
  };
  onClose?: () => void;
}

const AutoHideSnackbar = ({
  message,
  onClose,
  severity,
  title,
  anchorOrigin,
  ...props
}: AutoHideSnackbarProps) => (
  <Snackbar
    open={!!message}
    autoHideDuration={6000}
    anchorOrigin={anchorOrigin}
    onClose={onClose}
    {...props}
  >
    <StyledAlert onClose={onClose} severity={severity} title={title}>
      {message}
    </StyledAlert>
  </Snackbar>
);

export default AutoHideSnackbar;

import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    labelSmall: React.CSSProperties;
    label: React.CSSProperties;
    labelLarge: React.CSSProperties;
    bodyXLarge: React.CSSProperties;
    bodyLarge: React.CSSProperties;
    body: React.CSSProperties;
    bodySmall: React.CSSProperties;
    bodyXSmall: React.CSSProperties;
    buttonXLarge: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    buttonXSmall: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    labelSmall?: React.CSSProperties;
    label: React.CSSProperties;
    labelLarge?: React.CSSProperties;
    bodyXLarge: React.CSSProperties;
    bodyLarge: React.CSSProperties;
    body: React.CSSProperties;
    bodySmall: React.CSSProperties;
    bodyXSmall: React.CSSProperties;
    buttonXLarge: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    buttonXSmall: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    labelSmall: true;
    label: true;
    labelLarge: true;
    bodyXLarge: true;
    bodyLarge: true;
    body: true;
    bodySmall: true;
    bodyXSmall: true;
    buttonXLarge: true;
    buttonLarge: true;
    buttonSmall: true;
    buttonXSmall: true;
  }
}

const fontWeight = {
  light: 400,
  regular: 500,
  medium: 600,
  bold: 700,
};

const typography: TypographyOptions = {
  fontFamily: "Inter",
  fontWeightLight: fontWeight.light,
  fontWeightRegular: fontWeight.regular,
  fontWeightMedium: fontWeight.medium,
  fontWeightBold: fontWeight.bold,
  h1: {
    fontSize: "3rem",
    lineHeight: 1.2,
    fontWeight: fontWeight.bold,
    letterSpacing: "-0.067rem",
  },
  h2: {
    fontSize: "2.5rem",
    lineHeight: 1.2,
    fontWeight: fontWeight.bold,
    letterSpacing: "-0.056rem",
  },
  h3: {
    fontSize: "2rem",
    lineHeight: 1.2,
    fontWeight: fontWeight.bold,
    letterSpacing: "-0.043rem",
  },
  h4: {
    fontSize: "1.625rem",
    lineHeight: 1.2,
    fontWeight: fontWeight.bold,
    letterSpacing: "-0.033rem",
  },
  h5: {
    fontSize: "1.375rem",
    lineHeight: 1.2,
    fontWeight: fontWeight.bold,
    letterSpacing: "-0.025rem",
  },
  h6: {
    fontSize: "1.125rem",
    lineHeight: 1.2,
    fontWeight: fontWeight.bold,
    letterSpacing: "-0.016rem",
  },
  subtitle1: {
    fontSize: "1rem",
    lineHeight: 1.2,
    fontWeight: fontWeight.regular,
    letterSpacing: "0.01rem",
  },
  subtitle2: {
    fontSize: "1.25rem",
    lineHeight: 1.2,
    fontWeight: fontWeight.regular,
    letterSpacing: "0.02rem",
  },
  bodyXLarge: {
    fontSize: "1.25rem",
    lineHeight: 1.5,
    fontWeight: fontWeight.light,
  },
  bodyLarge: {
    fontSize: "1.125rem",
    lineHeight: 1.5,
    fontWeight: fontWeight.light,
  },
  body: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: fontWeight.light,
  },
  bodyXSmall: {
    fontSize: "0.75rem",
    lineHeight: 1.5,
    fontWeight: fontWeight.light,
  },
  bodySmall: {
    fontSize: "0.875rem",
    lineHeight: 1.5,
    fontWeight: fontWeight.light,
  },
  buttonXLarge: {
    fontSize: "1.25rem",
    lineHeight: 1.5,
    fontWeight: fontWeight.regular,
  },
  buttonLarge: {
    fontSize: "1.125rem",
    lineHeight: 1.5,
    fontWeight: fontWeight.regular,
  },
  button: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: fontWeight.regular,
    textTransform: "none",
  },
  buttonSmall: {
    fontSize: "0.875rem",
    lineHeight: 1.5,
    fontWeight: fontWeight.regular,
  },
  buttonXSmall: {
    fontSize: "0.75rem",
    lineHeight: 1.5,
    fontWeight: fontWeight.regular,
  },
  labelSmall: {
    fontSize: "0.875rem",
    fontWeight: fontWeight.medium,
    lineHeight: 1.5,
    letterSpacing: "-0.005rem",
  },
  label: {
    fontSize: "1rem",
    fontWeight: fontWeight.medium,
    lineHeight: 1.5,
    letterSpacing: "-0.011rem",
  },
  labelLarge: {
    fontSize: "1.125rem",
    fontWeight: fontWeight.medium,
    lineHeight: 1.5,
    letterSpacing: "-0.016rem",
    display: "block",
  },
  caption: {
    fontSize: "0.75rem",
    lineHeight: 1.25,
    fontWeight: fontWeight.light,
    letterSpacing: "0.02rem",
  },
  overline: {
    fontSize: "0.75rem",
    lineHeight: 1.5,
    fontWeight: fontWeight.regular,
    letterSpacing: "0.05rem",
  },
};

export default typography;

import { Components, Theme } from "@mui/material";

const MuiInputBase: Components<Theme>["MuiInputBase"] = {
  styleOverrides: {
    root: ({ theme: { typography } }) => ({
      ...typography.body,
    }),
  },
};

export default MuiInputBase;

import { Box } from "@mui/material";

import theme from "utils/theme";

const AppLayout = ({ children }: { children: React.ReactNode }) => (
  <Box sx={{ height: "100vh", bgcolor: theme.palette.background.default }}>
    {children}
  </Box>
);

export default AppLayout;

import { Components, Theme } from "@mui/material";

const MuiFormControl: Components<Theme>["MuiFormControl"] = {
  styleOverrides: {
    root: ({
      theme: {
        palette: { common, grey, secondary },
      },
    }) => ({
      "& label, & ::placeholder": {
        color: grey[700],
      },
      "&:hover": {
        "& label, & ::placeholder": {
          color: common.black,
        },
      },
      "& label.Mui-focused": {
        color: common.black,
      },
      "& label.Mui-disabled": {
        color: grey[400],
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: grey[400],
        },
        "&:hover fieldset": {
          borderWidth: 2,
          borderColor: grey[500],
        },
        "&.Mui-disabled fieldset": {
          borderColor: grey[400],
        },
        "&.Mui-disabled:hover fieldset": {
          borderWidth: 1,
          borderColor: grey[400],
        },
        "&.Mui-focused fieldset": {
          borderWidth: 2,
          borderColor: secondary.main,
        },
        "&.Mui-error fieldset": {
          borderWidth: 2,
        },
      },
    }),
  },
};

export default MuiFormControl;

import handleError from "apis/handleError";

const isCorrect = (code: number) => code === 304 || (code >= 200 && code < 300);

const handleResponse = (response: Response) =>
  response
    .json()
    .catch(() => handleError({ type: "json_error" }))
    .then((body) =>
      isCorrect(response.status)
        ? body
        : handleError({ httpStatus: response.status, error: body })
    );

export default handleResponse;

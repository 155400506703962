const DEFAULT_ERROR = "Une erreur est survenue";

const handleInternalError = (type: string) => {
  switch (type) {
    case "no_network":
      throw new Error("Une erreur de réseau est survenue");
    case "json_error":
      throw new Error("Une erreur de parsing JSON est survenue");
    default:
      throw new Error(DEFAULT_ERROR);
  }
};

interface ApiErrorType {
  httpStatus: number;
  error?: RequestError;
}

const typeError = (error: RequestError): RequestError => {
  if ("error_description" in error)
    return { ...error, __typename: "OAuthError" };
  if ("errors" in error && Array.isArray(error.errors))
    return { ...error, __typename: "GraphqlErrors" };
  if ("error" in error)
    return { ...(error as ApiError), __typename: "ApiError" };

  return { ...error, __typename: "UnknownError" };
};

const handleApiError = ({ httpStatus, error }: ApiErrorType) => {
  if (error?.__typename === "OAuthError") {
    if (httpStatus === 400 && error.error === "invalid_grant")
      throw new Error("Email ou mot de passe invalide");
    else throw new Error(error.error_description);
  }

  if (error?.__typename === "ApiError") throw error; // eslint-disable-line @typescript-eslint/no-throw-literal

  if (httpStatus === 401) throw new Error("L'authentification a échoué");
  if (httpStatus === 403) throw new Error("Cette action n'est pas autorisée");
  if (httpStatus >= 500)
    throw new Error("Une erreur est survenue sur le serveur");

  throw new Error(DEFAULT_ERROR);
};

interface ErrorType extends Partial<ApiErrorType> {
  type?: string;
}

const handleError = ({ httpStatus, type, error }: ErrorType) => {
  // iterate over application error
  if (type) return handleInternalError(type);

  // iterate over api error
  if (httpStatus)
    return handleApiError({ httpStatus, error: error && typeError(error) });

  throw new Error(DEFAULT_ERROR);
};

export default handleError;

import { ApolloLink, HttpLink } from "@apollo/client";
import fetch from "isomorphic-fetch";

import clientSideAuthLink from "lib/apollo/links/clientSideAuthLink";
import errorLink from "lib/apollo/links/errorLink";

const graphqlApiLink = (accessToken?: string) =>
  ApolloLink.from([
    errorLink,
    clientSideAuthLink,
    new HttpLink({
      uri: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
      credentials: "same-origin",
      headers: { Authorization: `Bearer ${accessToken}` },
      fetch,
    }),
  ]);

export default graphqlApiLink;

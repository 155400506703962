import { Shadows } from "@mui/material/styles/shadows";

const shadows: Shadows = [
  "none",
  "0px 0.25px 2px 0.25px rgba(0, 0, 0, 0.07)",
  "0px 0.5px 4px 0.5px rgba(0, 0, 0, 0.07)",
  "0px 0.75px 6px 0.75px rgba(0, 0, 0, 0.08)",
  "0px 1px 8px 1px rgba(0, 0, 0, 0.08)",
  "0px 1.25px 10px 1.25px rgba(0, 0, 0, 0.09)",
  "0px 1.5px 12px 1.5px rgba(0, 0, 0, 0.09)",
  "0px 1.75px 14px 1.75px rgba(0, 0, 0, 0.09)",
  "0px 2px 16px 2px rgba(0, 0, 0, 0.1)",
  "0px 2.25px 18px 2.25px rgba(0, 0, 0, 0.1)",
  "0px 2.5px 20px 2.5px rgba(0, 0, 0, 0.11)",
  "0px 2.75px 22px 2.75px rgba(0, 0, 0, 0.11)",
  "0px 3px 24px 3px rgba(0, 0, 0, 0.11)",
  "0px 3.25px 26px 3.25px rgba(0, 0, 0, 0.12)",
  "0px 3.5px 28px 3.5px rgba(0, 0, 0, 0.12)",
  "0px 3.75px 30px 3.75px rgba(0, 0, 0, 0.13)",
  "0px 4px 32px 4px rgba(0, 0, 0, 0.13)",
  "0px 4.25px 34px 4.25px rgba(0, 0, 0, 0.13)",
  "0px 4.5px 36px 4.5px rgba(0, 0, 0, 0.14)",
  "0px 4.75px 38px 4.75px rgba(0, 0, 0, 0.14)",
  "0px 5px 40px 5px rgba(0, 0, 0, 0.15)",
  "0px 5.25px 42px 5.25px rgba(0, 0, 0, 0.15)",
  "0px 5.5px 44px 5.5px rgba(0, 0, 0, 0.15)",
  "0px 5.75px 46px 5.75px rgba(0, 0, 0, 0.16)",
  "0px 6px 48px 6px rgba(0, 0, 0, 0.16)",
];

export default shadows;

import { Components, Theme } from "@mui/material";

const MuiChip: Components<Theme>["MuiChip"] = {
  styleOverrides: {
    root: ({ ownerState, theme: { palette } }) => {
      const colorPalette =
        ownerState.color && ownerState.color !== "default"
          ? palette[ownerState.color]
          : {
              main: palette.grey[700],
              dark: palette.grey[900],
              background: palette.grey[300],
              backgroundHover: palette.grey[400],
            };

      return {
        borderRadius: 50,
        height: 29,
        color: colorPalette.dark,
        backgroundColor: colorPalette.background,
        "&:hover": {
          backgroundColor: colorPalette.backgroundHover,
          "& .MuiChip-deleteIcon": {
            display: "inline-block",
          },
        },
        "&.Mui-disabled": {
          backgroundColor: palette.grey[300],
          opacity: 1,
          "&, & .MuiChip-icon": { color: palette.grey[500] },
        },
        "& .MuiChip-deleteIcon": {
          display: "none",
          color: colorPalette.dark,
          "&:hover": {
            color: colorPalette.main,
          },
        },
      };
    },
    label: ({ theme: { typography } }) => ({
      ...typography.buttonSmall,
    }),
    icon: {
      fontSize: 21,
      marginLeft: 8,
    },
  },
};

export default MuiChip;

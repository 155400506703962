import { PaletteOptions } from "@mui/material";
import { Property } from "csstype";

declare module "@mui/material/styles" {
  interface Palette {
    specials: {
      deepBlue: Property.Color;
      yellow: Property.Color;
      yellowBackground: Property.Color;
      sidebar: Property.Color;
    };
    gradients: {
      mobileAppBar: (d: number) => React.CSSProperties["background"];
    };
    referral: {
      background: Property.Color;
    };
    graph: {
      darkBlue: Property.Color;
      lightBlue: Property.Color;
      darkGreen: Property.Color;
      lightGreen: Property.Color;
    };
  }

  interface PaletteOptions {
    specials: {
      deepBlue: Property.Color;
      yellow: Property.Color;
      yellowBackground: Property.Color;
      sidebar: Property.Color;
    };
    gradients: {
      mobileAppBar: (d: number) => React.CSSProperties["background"];
    };
    referral: {
      background: Property.Color;
    };
    graph: {
      darkBlue: Property.Color;
      lightBlue: Property.Color;
      darkGreen: Property.Color;
      lightGreen: Property.Color;
    };
  }

  interface PaletteColor {
    background: string;
    backgroundHover: string;
  }

  interface SimplePaletteColorOptions {
    background: string;
    backgroundHover: string;
  }
}

const palette: PaletteOptions = {
  primary: {
    dark: "#242560",
    main: "#5E60CB",
    light: "#BCBDF7",
    contrastText: "#FAFAFA",
    background: "#f9f9fe",
    backgroundHover: "#f3f3fd",
  },
  secondary: {
    dark: "#242560",
    main: "#5E60CB",
    light: "#BCBDF7",
    contrastText: "#FAFAFA",
    background: "#f9f9fe",
    backgroundHover: "#f3f3fd",
  },
  success: {
    dark: "#0B6B48",
    main: "#13BE81",
    light: "#5DEFBB",
    contrastText: "#FAFAFA",
    background: "#E8F9F3",
    backgroundHover: "#D0F2E6",
  },
  info: {
    dark: "#015A76",
    main: "#029FD1",
    light: "#50D3FD",
    contrastText: "#FAFAFA",
    background: "#E6F6FB",
    backgroundHover: "#CCECF6",
  },
  warning: {
    dark: "#A15208",
    main: "#F37E12",
    light: "#F9BD87",
    contrastText: "#FAFAFA",
    background: "#FEF3E8",
    backgroundHover: "#FDE5D0",
  },
  error: {
    dark: "#941212",
    main: "#E41E1E",
    light: "#F18B8B",
    contrastText: "#FAFAFA",
    background: "#FDE9E9",
    backgroundHover: "#FAD2D2",
  },
  background: {
    default: "#F3F5FB",
    paper: "#FFFFFF",
  },
  text: {
    primary: "#000",
    disabled: "#BDBDBD",
  },
  specials: {
    deepBlue: "#242560",
    yellow: "#FFC700",
    yellowBackground: "rgba(253, 225, 80, 0.3)",
    sidebar: "#3F4098",
  },
  graph: {
    darkBlue: "#015A76",
    lightBlue: "#CCECF6",
    darkGreen: "#1A963D",
    lightGreen: "#CAEFD5",
  },
  gradients: {
    mobileAppBar: (degrees) =>
      `linear-gradient(${degrees}deg, rgb(28,64,171) 0%, #11287D 100%)`,
  },
  referral: {
    background: "#E1E2FF",
  },
  grey: {
    50: "#FAFAFA",
    100: "#F8F8F8",
    200: "#EEEEEE",
    300: "#E0E0E0",
    400: "#BDBDBD",
    500: "#9E9E9E",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
  },
};

export default palette;

import { createContext, useState, useContext, ReactNode, useMemo } from "react";

interface InfobarContextType {
  isInfobarVisible: boolean;
  setInfobarVisible: (visible: boolean) => void;
}

const InfobarContext = createContext<InfobarContextType | undefined>(undefined);

export const InfobarProvider = ({ children }: { children: ReactNode }) => {
  const [isInfobarVisible, setInfobarVisible] = useState(false);

  const initialContestValues = useMemo(
    () => ({
      isInfobarVisible,
      setInfobarVisible,
    }),
    [isInfobarVisible, setInfobarVisible]
  );

  return (
    <InfobarContext.Provider value={initialContestValues}>
      {children}
    </InfobarContext.Provider>
  );
};

export const useInfobar = () => {
  const context = useContext(InfobarContext);
  if (context === undefined) {
    throw new Error("useInfobar must be used within an InfobarProvider");
  }
  return context;
};

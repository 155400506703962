import { Components, Theme } from "@mui/material";

const MuiAlert: Components<Theme>["MuiAlert"] = {
  styleOverrides: {
    root: ({ ownerState, theme: { palette } }) => ({
      color: palette[ownerState.severity || "success"].dark,
      "& .MuiAlert-icon": {
        color: palette[ownerState.severity || "success"].main,
      },
      "& .MuiAlertTitle-root": {
        color: palette[ownerState.severity || "success"].dark,
      },
      "& .MuiAlert-action": {
        mr: 0,
      },
      "& a": { color: palette[ownerState.severity || "success"].main },
      backgroundColor: palette[ownerState.severity || "success"].background,
    }),
    message: ({ theme: { typography } }) => ({
      ...typography.bodySmall,
    }),
    icon: { fontSize: 24 },
  },
};

export default MuiAlert;

import { Observable, FetchResult } from "@apollo/client";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import cookies from "js-cookie";

import getAccessToken from "apis/getAccessToken";
import getTokenInfo from "apis/getTokenInfo";
import logout from "utils/logout";

// Checks whether the error response is 401 because of an 'invalid_token'
const isUnauthorizedToken = (networkError: ErrorResponse["networkError"]) => {
  if (networkError && "response" in networkError) {
    const fetchResponse = networkError.response;
    return (
      fetchResponse.status === 401 &&
      fetchResponse?.headers
        .get("WWW-Authenticate")
        ?.match(/error="invalid_token"/)
    );
  }
  return false;
};

/* eslint-disable consistent-return */
const errorLink = onError(
  ({ networkError, operation, forward }): Observable<FetchResult> | void => {
    if (isUnauthorizedToken(networkError)) {
      // ATM We can't handle 401 errors with SSR to refresh the token (how to retrieve the refresh_token?)
      if (typeof window === "undefined") return;

      const refreshToken = cookies.get("refresh_token");
      if (!refreshToken) {
        logout();
      } else
        return new Observable((observer) => {
          getAccessToken({
            grant_type: "refresh_token",
            refresh_token: refreshToken,
          })
            .then(() => {
              // Retry last failed request
              forward(operation).subscribe(observer);
            })
            .catch((error) => {
              const accessToken = cookies.get("access_token");

              if (!accessToken) {
                observer.error(error);
                logout();
              } else {
                getTokenInfo(accessToken)
                  .then(() => {
                    forward(operation).subscribe(observer);
                  })
                  .catch((err) => {
                    observer.error(err);
                    logout();
                  });
              }
            });
        });
    }
  }
);

export default errorLink;

import fetch from "isomorphic-fetch";

import handleNetworkError from "apis/handleNetworkError";
import handleResponse from "apis/handleResponse";

const getTokenInfo = (accessToken: string) =>
  fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/oauth/token/info?access_token=${accessToken}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .catch(handleNetworkError)
    .then(handleResponse);

export default getTokenInfo;

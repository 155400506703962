import Router from "next/router";
import cookies from "js-cookie";

const logout = () => {
  cookies.remove("access_token");
  cookies.remove("refresh_token");
  cookies.remove("role");
  cookies.remove("user_id");
  // Support logging out from all windows
  window.localStorage.setItem("logout", Date.now().toString());
  Router.push("/login");
};

export default logout;

import { Components, Theme } from "@mui/material";

const MuiButton: Components<Theme>["MuiButton"] = {
  variants: [
    {
      props: { size: "small" },
      style: ({ theme: { typography, spacing } }) => ({
        ...typography.buttonSmall,
        padding: `${spacing(0.75)} ${spacing(1.5)}`,
      }),
    },
    {
      props: { size: "medium" },
      style: ({ theme: { spacing } }) => ({
        padding: `${spacing(1)} ${spacing(2)}`,
      }),
    },
    {
      props: { size: "large" },
      style: ({ theme: { typography, spacing } }) => ({
        ...typography.buttonLarge,
        padding: `${spacing(1.5)} ${spacing(2)}`,
      }),
    },
    {
      props: { variant: "outlined", color: "primary" },
      style: ({ theme: { palette } }) => ({
        borderColor: palette.primary.main,
        "&:hover": {
          backgroundColor: palette.primary.backgroundHover,
        },
      }),
    },
    {
      props: { variant: "text", color: "primary" },
      style: ({ theme: { palette } }) => ({
        "&:hover": {
          backgroundColor: palette.primary.background,
        },
      }),
    },
    {
      props: { variant: "muted" },
      style: ({ theme: { palette } }) => ({
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey["300"]}`,
        color: palette.grey["600"],
      }),
    },
  ],
  styleOverrides: {
    root: {
      borderRadius: 8,
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
    contained: ({ theme: { palette } }) => ({
      "&.Mui-disabled": {
        color: palette.grey[600],
        backgroundColor: palette.grey[300],
      },
    }),
    outlined: ({ theme: { palette } }) => ({
      "&.Mui-disabled": {
        backgroundColor: palette.grey[100],
        color: palette.grey[400],
        borderColor: palette.grey[400],
      },
    }),
    disabled: ({ theme: { palette } }) => ({
      color: palette.grey[400],
    }),
  },
};

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    muted: true;
  }
}

export default MuiButton;

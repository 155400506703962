import cookies from "js-cookie";

interface SignupResponse {
  access_token: string;
  token_type: "Bearer";
  expires_in: number;
  refresh_token: string;
  scope: "public";
  created_at: Date;
}

const setTokens = (response: SignupResponse) => {
  const { access_token: accessToken, refresh_token: refreshToken } = response;
  const expirationDate = new Date(
    new Date().getTime() + response.expires_in * 1000
  );
  cookies.set("access_token", accessToken, {
    expires: expirationDate,
    secure: process.env.NODE_ENV !== "development",
  });
  cookies.set("refresh_token", refreshToken, {
    secure: process.env.NODE_ENV !== "development",
  });
  return accessToken;
};

export default setTokens;

import { createTheme, Theme, responsiveFontSizes } from "@mui/material/styles";

import components from "./components";
import palette from "./palette";
import shadows from "./shadows";
import typography from "./typography";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const theme = responsiveFontSizes(
  createTheme({
    typography,
    palette,
    components,
    shadows,
  })
);

export default theme;

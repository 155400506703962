import { Components, Theme } from "@mui/material";

const MuiTab: Components<Theme>["MuiTab"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme: { palette, typography, spacing } }) => ({
      ...typography.buttonSmall,
      color: palette.grey[500],
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    }),
  },
};

export default MuiTab;

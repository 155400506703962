import { Components, Theme } from "@mui/material";

import MuiAlert from "./MuiAlert";
import MuiButton from "./MuiButton";
import MuiChip from "./MuiChip";
import MuiFormControl from "./MuiFormControl";
import MuiFormHelperText from "./MuiFormHelperText";
import MuiIconButton from "./MuiIconButton";
import MuiInputBase from "./MuiInputBase";
import MuiListItem from "./MuiListItem";
import MuiListItemButton from "./MuiListItemButton";
import MuiMenuItem from "./MuiMenuItem";
import MuiTab from "./MuiTab";
import MuiTabs from "./MuiTabs";
import typography from "../typography";

const components: Components<Theme> = {
  MuiAlert,
  MuiAppBar: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { common },
        },
      }) => ({
        backgroundColor: common.white,
      }),
    },
  },
  MuiChip,
  MuiInputBase,
  MuiFormControl,
  MuiFormHelperText,
  MuiListItem,
  MuiListItemButton,
  MuiListItemIcon: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { common },
        },
      }) => ({
        color: common.white,
      }),
    },
  },
  MuiMenuItem,
  MuiTableCell: {
    styleOverrides: {
      stickyHeader: ({
        theme: {
          palette: { background, grey },
        },
      }) => ({
        backgroundColor: background.default,
        fontWeight: typography.fontWeightBold,
        color: grey[600],
        border: "none",
      }),
    },
  },
  MuiTab,
  MuiTabs,
  MuiTypography: {
    defaultProps: {
      variant: "body",
      variantMapping: {
        body: "p",
        bodyLarge: "p",
        bodyXLarge: "p",
        label: "p",
        labelLarge: "p",
      },
    },
  },
  MuiButton,
  MuiIconButton,
  MuiSwitch: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { primary },
        },
      }) => ({
        "& .MuiSwitch-thumb": {
          border: "1px solid",
          borderColor: primary.dark,
          boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
        },
        "& .MuiSwitch-track": {
          backgroundColor: primary.main,
        },
        ".Mui-checked": {
          "& .MuiSwitch-thumb": {
            backgroundColor: primary.dark,
          },
        },
      }),
    },
  },
};

export default components;

import { ForwardedRef, forwardRef } from "react";
import { AlertProps } from "@mui/lab";
import { Alert, AlertTitle, Button, ButtonProps } from "@mui/material";

export interface StyledAlertProps extends Omit<AlertProps, "action"> {
  innerRef?: ForwardedRef<HTMLDivElement>;
  buttonProps?: ButtonProps;
}

const StyledAlert = ({
  children,
  buttonProps,
  title,
  severity,
  innerRef,
  ...props
}: StyledAlertProps) => (
  <Alert
    ref={innerRef}
    severity={severity}
    sx={{
      ...(buttonProps && {
        "& .MuiAlert-action": { width: "100%", justifyContent: "end" },
      }),
      "& a": { color: `${severity}.main` },
    }}
    action={
      buttonProps && <Button color={severity} size="small" {...buttonProps} />
    }
    {...props}
  >
    {title && (
      <AlertTitle sx={children ? undefined : { mb: 0 }}>{title}</AlertTitle>
    )}
    {children}
  </Alert>
);

export default forwardRef<HTMLDivElement, StyledAlertProps>((props, ref) => (
  <StyledAlert innerRef={ref} {...props} />
));

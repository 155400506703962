import { Components, Theme } from "@mui/material";

const MuiListItemButton: Components<Theme>["MuiListItemButton"] = {
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: palette.grey["200"],
      },
    }),
  },
};

export default MuiListItemButton;

import { HttpLink } from "@apollo/client";
import fetch from "isomorphic-fetch";

const contentfulApiLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_CONTENTFUL_API_URL,
  credentials: "same-origin",
  headers: {
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_CDA_KEY}`,
  },
  fetch,
});

export default contentfulApiLink;
